import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { keyframes } from '@emotion/react';

// Keyframe animations
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeroSection = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(to right top, #111862, #0c1c83, #061ea5, #051fc8, #121ceb)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        textAlign: 'center',
        padding: { xs: '60px 20px', md: '100px 20px' },
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay for contrast
          zIndex: 1,
        },
      }}
    >
      <Box sx={{ position: 'relative', zIndex: 2 }}>
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontSize: { xs: '2.5rem', md: '4rem' },
            fontWeight: '700',
            animation: `${fadeInUp} 1s ease-in-out`,
          }}
        >
          Drive a Cleaner Tomorrow
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { xs: '1.1rem', md: '1.3rem' },
            marginBottom: 4,
            animation: `${fadeInUp} 1.2s ease-in-out`,
          }}
        >
          Your car deserves the best care. Experience premium cleaning services that make every journey shine.
        </Typography>
        <Button
          variant="contained"
          sx={{
            marginRight: '10px',
            backgroundColor: '#ff9800',
            '&:hover': { backgroundColor: '#e68900' },
            animation: `${fadeInUp} 1.4s ease-in-out`,
          }}
        >
          Get A Quote
        </Button>
        <Button
          variant="outlined"
          sx={{
            color: '#ff9800',
            borderColor: '#ff9800',
            '&:hover': { backgroundColor: '#ff9800', color: '#fff' },
            animation: `${fadeInUp} 1.6s ease-in-out`,
          }}
        >
          Contact Us
        </Button>
      </Box>
    </Box>
  );
};

export default HeroSection;
