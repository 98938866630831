import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Link, Box } from '@mui/material';

// Import images from the src/images folder
import img1 from '../images/img-1.png';
import img2 from '../images/img-2.png';
import img3 from '../images/img-3.png';

const services = [
  {
    title: 'Express Exterior',
    image: img1,
    description: 'Experience a quick yet thorough exterior wash that leaves your car sparkling clean in minutes.',
  },
  {
    title: 'Full Service Car Wash',
    image: img2,
    description: 'A complete inside-out cleaning that refreshes your car and restores its shine.',
  },
  {
    title: 'Auto Detailing',
    image: img3,
    description: 'Professional detailing to rejuvenate your vehicle, enhancing its appearance and value.',
  },
];

const ServicesSection = () => {
  return (
    <Container sx={{ py: 6 }}>
      <Typography variant="h4" textAlign="center" marginBottom={4} sx={{ fontWeight: '700', color: '#333' }}>
        Our Services
      </Typography>
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card 
              sx={{ 
                boxShadow: 5, 
                borderRadius: 2, 
                transition: 'transform 0.3s', 
                '&:hover': { transform: 'scale(1.05)' } 
              }}
            >
              <CardMedia
                component="img"
                height="220"
                image={service.image}
                alt={service.title}
              />
              <CardContent>
                <Typography variant="h5" component="div" sx={{ fontWeight: '600', color: '#2a2a2a' }}>
                  {service.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 2, mb: 2 }}>
                  {service.description}
                </Typography>
                <Box textAlign="center">
                  <Link href="#" underline="none" sx={{ color: '#1e88e5', fontWeight: '600' }}>
                    Learn More
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ServicesSection;
