import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../images/HD_CAR_SPA.png';

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#fff', color: '#000' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Logo */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src={Logo} 
            alt="CarClean Logo" 
            style={{ width: '300px', height: 'auto' }}  // Adjust size as needed
          />
        </Box>
        {/* Navigation Links (Hidden on small screens) */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 3 }}>
          <Typography variant="body1" sx={{ cursor: 'pointer' }}>Home</Typography>
          <Typography variant="body1" sx={{ cursor: 'pointer' }}>About</Typography>
          <Typography variant="body1" sx={{ cursor: 'pointer' }}>Services</Typography>
          <Typography variant="body1" sx={{ cursor: 'pointer' }}>Contact</Typography>
        </Box>

        {/* Hamburger Menu Icon (Visible only on small screens) */}
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleMenuOpen}
          sx={{ display: { md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        {/* Mobile Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{ display: { md: 'none' } }}
        >
          <MenuItem onClick={handleMenuClose}>Home</MenuItem>
          <MenuItem onClick={handleMenuClose}>About</MenuItem>
          <MenuItem onClick={handleMenuClose}>Services</MenuItem>
          <MenuItem onClick={handleMenuClose}>Contact</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
