import React from 'react';
import { Box, Typography, Button, Grid, Card, CardContent, CardMedia, Container, AppBar, Toolbar, IconButton, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Header from './Header';
import ServicesSection from './ServicesSection';
import HeroSection from './HeroSection';

const HomePage = () => {
  return (
    <Box sx={{ fontFamily: 'Poppins, sans-serif' }}>
      {/* Header */}
      <Header />

      {/* Hero Section */}
      <HeroSection />

      {/* Services Section */}
     <ServicesSection />

      {/* Footer */}
      <Box sx={{ backgroundColor: '#212121', color: '#fff', padding: '30px 0', marginTop: '40px' }}>
        <Container sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between' }}>
          <Box sx={{ mb: { xs: 3, md: 0 } }}>
            <Typography variant="h6" sx={{ fontWeight: '600' }}>Contact Us</Typography>
            <Typography>Location: 7, Poonamallee, Chennai - 56</Typography>
            <Typography>Call: (+91) 91765 11908</Typography>
            <Typography>Email: contact@hdcarspa.com</Typography>
          </Box>
          <Box sx={{ mb: { xs: 3, md: 0 } }}>
            <Typography variant="h6" sx={{ fontWeight: '600' }}>Useful Links</Typography>
            <Link href="#" color="inherit" underline="hover">Home</Link><br />
            <Link href="#" color="inherit" underline="hover">About</Link><br />
            <Link href="#" color="inherit" underline="hover">Service</Link>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: '600' }}>Opening Time</Typography>
            <Typography>Mon: 09:00am to 08:00pm</Typography>
          </Box>
        </Container>
        <Typography variant="body2" textAlign="center" marginTop={2}>
          © 2024 All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default HomePage;
