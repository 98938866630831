import React from 'react';
import { CssBaseline, Container } from '@mui/material';
import HomePage from './components/HomePage';
import './App.css';

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <HomePage />
    </div>
  );
}

export default App;
